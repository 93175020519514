import React, { Fragment, FC } from 'react';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import Helmet from 'react-helmet';
import { theme } from '../../styles/theme';

// https://material-ui.com/customization/components/#global-css-override
const GlobalCss = withStyles({
  '@global': {
    p: {
      fontWeight: 300,
    },
    'li > p': {
      margin: '0',
    },
    '@keyframes bounce': {
      '0%, 20%, 50%, 80%, 100%': {
        transform: 'translateY(0)',
      },
      '40%': {
        transform: 'translateY(-30px)',
      },
      '60%': {
        transform: 'translateY(-15px)',
      },
    },
  },
})(() => null);

// TODO: looks like there is better way to attach those fonts via npm. Do it one day to speed up the site even more.
export const Layout: FC = ({ children }) => {
  return (
    <Fragment>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalCss />
        {children}
      </ThemeProvider>
    </Fragment>
  );
};
