import { createMuiTheme } from '@material-ui/core/styles';
// import { Palette } from '@material-ui/core/styles/createPalette';

// TODO: make it properly
const defaultTheme = createMuiTheme();

defaultTheme.overrides = {
  MuiAvatar: {
    root: {
      display: 'inline-flex',
      top: '8px',
    },
  },
};

// defaultTheme.palette.primary.main = '#9c27b0';
// defaultTheme.palette.secondary.main = '#9c27b0';
//   secondary: {
//     ...defaultTheme.palette.secondary,
//     main: '#fafafa',
//   },
//   warning: {
//     ...defaultTheme.palette.warning,
//     main: '#ff9800',
//   },
//   error: {
//     ...defaultTheme.palette.error,
//     main: '#f44336',
//   },
//   success: {
//     ...defaultTheme.palette.success,
//     main: '#4caf50',
//   },
//   info: {
//     ...defaultTheme.palette.info,
//     main: '#00acc1',
//   },
// } as Palette;

export { defaultTheme as theme };
