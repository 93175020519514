// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---gatsby-theme-teklog-src-templates-post-tsx": () => import("./../../gatsby-theme-teklog/src/templates/post.tsx" /* webpackChunkName: "component---gatsby-theme-teklog-src-templates-post-tsx" */),
  "component---gatsby-theme-teklog-src-templates-series-template-tsx": () => import("./../../gatsby-theme-teklog/src/templates/seriesTemplate.tsx" /* webpackChunkName: "component---gatsby-theme-teklog-src-templates-series-template-tsx" */),
  "component---gatsby-theme-teklog-src-pages-cookies-tsx": () => import("./../../gatsby-theme-teklog/src/pages/cookies.tsx" /* webpackChunkName: "component---gatsby-theme-teklog-src-pages-cookies-tsx" */),
  "component---gatsby-theme-teklog-src-pages-index-tsx": () => import("./../../gatsby-theme-teklog/src/pages/index.tsx" /* webpackChunkName: "component---gatsby-theme-teklog-src-pages-index-tsx" */),
  "component---gatsby-theme-teklog-src-pages-series-tsx": () => import("./../../gatsby-theme-teklog/src/pages/series.tsx" /* webpackChunkName: "component---gatsby-theme-teklog-src-pages-series-tsx" */)
}

