module.exports = [{
      plugin: require('../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"wrapperStyle":"box-shadow: 5px 5px 9px 0px rgba(0,0,0,0.5);","quality":75,"withWebp":{"quality":75}},
    },{
      plugin: require('../../gatsby-theme-teklog/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"../output"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TekLog - Technical Blog from TekMi","short_name":"TekLog","start_url":"/","background_color":"#f7f0eb","theme_color":"#663399","display":"minimal-ui","icon":"assets/images/tekmi-icon.svg"},
    }]
