import { StylesProvider } from '@material-ui/core/styles';
import React from 'react';
import { StylesProviderProps } from './src/styles/StylesProviderProps';
import { Layout } from './src/components/layout/layout';

import './src/styles/prims-my.css';
import './src/styles/base-table.css';

export const onInitialClientRender = () => {
  if (process.env.BUILD_STAGE === 'develop') {
    return;
  }

  // Remove styles that were inserted during SSR step. Inspired by https://github.com/hupe1980/gatsby-plugin-material-ui/blob/master/gatsby-plugin-material-ui/src/gatsby-browser.js#L14
  const jssStyles = document.querySelector('#jss-server-side');
  if (jssStyles) {
    jssStyles.remove();
  }
};

export const wrapRootElement = ({ element }) => {
  return (
    <StylesProvider {...StylesProviderProps}>
      <Layout>{element}</Layout>
    </StylesProvider>
  );
};

/**
 * @param {RouteUpdateArgs} args
 *
 * @see more https://developers.google.com/analytics/devguides/collection/gtagjs/pages#pageview_parameters
 */
export const onRouteUpdate = (args) => {
  /* eslint-disable @typescript-eslint/camelcase */
  const sendPageView = () => {
    window.gtag &&
      window.gtag('event', 'page_view', {
        page_title: document.title,
        page_location: args.location.href,
        page_path: args.location.pathname,
      });
  };
  /* eslint-enable @typescript-eslint/camelcase */

  // @see for inspiration https://github.com/gatsbyjs/gatsby/pull/12033/files
  if ('requestAnimationFrame' in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    setTimeout(sendPageView, 32);
  }
};
